/* For use in src/lib/core/theming/_palette.scss */
$dark-primary-text: #000000;
$light-primary-text: #ffffff;
$washed-primary-text: #929292;

/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #eaf7f9,
    100 : #cbecf1,
    200 : #a8e0e7,
    300 : #85d3dd,
    400 : #6bc9d6,
    500 : #51c0cf,
    600 : #4abaca,
    700 : #40b2c3,
    800 : #37aabd,
    900 : #279cb2,
    A100 : #f1fdff,
    A200 : #bef4ff,
    A400 : #8bebff,
    A700 : #71e7ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-accent: (
    50 : #f9f7e8,
    100 : #f1ebc5,
    200 : #e7de9e,
    300 : #ddd177,
    400 : #d6c759,
    500 : #cfbd3c,
    600 : #cab736,
    700 : #c3ae2e,
    800 : #bda627,
    900 : #b2981a,
    A100 : #fffbe7,
    A200 : #fff1b4,
    A400 : #ffe881,
    A700 : #ffe368,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-warn: (
    50 : #f9eeed,
    100 : #f1d5d1,
    200 : #e7bab2,
    300 : #dd9e93,
    400 : #d6897c,
    500 : #cf7465,
    600 : #ca6c5d,
    700 : #c36153,
    800 : #bd5749,
    900 : #b24437,
    A100 : #fffdfd,
    A200 : #ffcfca,
    A400 : #ffa197,
    A700 : #ff8a7e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary: (
    50 : #ebf0f9,
    100 : #cedaf1,
    200 : #adc2e7,
    300 : #8ca9dd,
    400 : #7496d6,
    500 : #5b84cf,
    600 : #537cca,
    700 : #4971c3,
    800 : #4067bd,
    900 : #2f54b2,
    A100 : #f6f9ff,
    A200 : #c3d3ff,
    A400 : #90aeff,
    A700 : #779bff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-corr: (
    50 : #e9f9f2,
    100 : #c8f1de,
    200 : #a3e7c9,
    300 : #7eddb3,
    400 : #62d6a2,
    500 : #46cf92,
    600 : #3fca8a,
    700 : #37c37f,
    800 : #2fbd75,
    900 : #20b263,
    A100 : #ebfff4,
    A200 : #b8ffd7,
    A400 : #85ffba,
    A700 : #6cffab,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
