//import mat to define palettes and theme
@use "@angular/material" as mat;
//import theme-used palette
@use "./telephonDigital-palette";
//import theme-used typography
@use "../gen/default-typography";
//import the mat-component style exposer mixins
@use "src/styles/gen/theme-to-material-component-exposer";
//import the custom-component CSS class defining mixin
@use "src/styles/gen/theme-to-css-classes-exposer";
//define theme-used palettes
$telephonDigital-primary: mat.define-palette(telephonDigital-palette.$md-primary, 500);
$telephonDigital-accent: mat.define-palette(telephonDigital-palette.$md-accent, A200, A100, A400);
$telephonDigital-warn: mat.define-palette(telephonDigital-palette.$md-warn);
//define theme
$telephonDigital-theme: mat.define-light-theme(
    (
      color:(
        primary: $telephonDigital-primary,
        accent: $telephonDigital-accent,
        warn: $telephonDigital-warn,
      ),
      //opting for default typography
      typography: default-typography.$default-typography,
      density: 0,
    )
);
//expose class-based styles
.telephonDigital-theme {
  //expose the theme-used colors to mat-components included in the exposer mixin
  @include theme-to-material-component-exposer.theme-color-to-material-component-exposer($telephonDigital-theme);
  //expose the theme-used colors to CSS-classes for custom components
  @include theme-to-css-classes-exposer.theme-to-css-class-exposer($telephonDigital-theme);

  @import "src/app/ui/gen/alert/alert.component";
}
